import React, { useEffect, useState } from 'react';
import styles from './SliderTwo.module.css'
import { fetchUrl } from '../config';


function SliderTwo() {
    const [sliders, setsliders] = useState([])
    useEffect(() => {
        fetch(`${fetchUrl}/api/slider/second/`)
            .then(res => res.json())
            .then(data => setsliders(data))
    }, [])
    return (
        <div>
            <div>
                {
                    sliders && sliders.length > 0 ?
                        <div>
                            <h4 className='text-center mb-3 mt-5'>Hot Deals</h4>
                            <div className={styles.MainImage}>
                                {sliders && sliders.slice(0, 4).map(data =>
                                    <>
                                        <img key={data.id} src={data.image} alt="deals" width={500} height={200} />
                                        <meta property="og:image" content={data.image} />
                                    </>
                                )}

                            </div>
                        </div>
                        : null
                }
            </div>
        </div>
    );
}

export default SliderTwo;