import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { fetchUrl } from '../config';
import Search from '../search/Search/Search';
import Loading from './LoadingCate';



export default function Categroypage() {
    const [isLoading, setIsLoading] = useState(true)
    const { categorySlug } = useParams()
    const [category, setCategory] = useState([])
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [categorySlug])

    useEffect(() => {
        fetch(`${fetchUrl}/api/categories/${categorySlug}/`)
            .then(res => res.json())
            .then(datas => { setCategory(datas); setIsLoading(false) })
    }, [categorySlug])

    return (
        <div>
            <Helmet>
                <title>{category ? category.name : 'Royel Shopping'}</title>
            </Helmet>
            {isLoading ? <Loading /> : null}
            <div className='mtop'>
                <div>
                    {
                        category && category.products && category.products.length === 0 ? <h3 className='text-center'>No product available in {category.name} </h3> :
                            <div className='container text-center mt-3'>
                                <p>Showing Products of</p>
                                <h6 className='mb-3'> {category.name}</h6>
                            </div>
                    }

                </div>

                <Search allProducts={category && category.products} />
            </div>
        </div>
    );
};

