import React from 'react';
import './LoadingRoyel.css'

function LoadingRoyel() {
  return (
    <ul className="loading-ul">
      <li className="loading-letter loading-letter-r">R</li>
      <li className="loading-letter loading-letter-o">o</li>
      <li className="loading-letter loading-letter-y">y</li>
      <li className="loading-letter loading-letter-e">e</li>
      <li className="loading-letter loading-letter-l">l</li>
      <li className="loading-letter loading-letter-s">S</li>
      <li className="loading-letter loading-letter-h">h</li>
      <li className="loading-letter loading-letter-o2">o</li>
      <li className="loading-letter loading-letter-p">p</li>
      <li className="loading-letter loading-letter-p2">p</li>
      <li className="loading-letter loading-letter-i">i</li>
      <li className="loading-letter loading-letter-n">n</li>
      <li className="loading-letter loading-letter-g">g</li>
    </ul>
  );
}

export default LoadingRoyel;