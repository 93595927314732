import React from 'react';
import { Helmet } from 'react-helmet';
import HeaderSection from '../HeaderSection/HeaderSection';
import SliderTwo from '../SliderTwo/SliderTwo';
import HeaderCategory from '../HeaderCategory/HeaderCategory';
import NewArrivel from '../NewArrivel/NewArrivel';
import HomeCategoryProducts from '../HomeCategoryProducts/HomeCategoryProducts';

function Home() {
    return (
        <div>
            <Helmet>
                <title>Royel Shopping !!</title>
            </Helmet>
            <HeaderSection />
            <SliderTwo />
            <HeaderCategory />
            <NewArrivel />
            <HomeCategoryProducts />
        </div>
    );
}

export default Home;