import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { Fade } from 'react-awesome-reveal';
import styles from './ProductView.module.css'
import AddToCart from './AddToCart';
import OrderModalView from './OrderModalView';
// import '../globals.css'



function ProductView(propsx) {
    const { products } = propsx

    return (
        <div>
            <div>

                <div className={styles.products}>

                    {
                        products && products.map(data =>
                            <div key={data.id}>
                                <Fade delay={100}>
                                    <div className={styles.productFirst}>
                                        <div className={styles.product}>
                                            <Link to={`/product/${data.slug}`}>
                                                <div className={styles.productImage}>
                                                    <img width={100} height={100} src={data.images[0] && data.images[0].image} alt='Product' />
                                                    <meta property="og:image" content={data.images[0] && data.images[0].image} />
                                                </div>
                                                <div className={styles.productName}>
                                                    <p>{data.name.slice(0, 50)} {data.name.length > 48 ? '...' : null}</p>
                                                </div>
                                            </Link>
                                            <div className={styles.priceSection}>
                                                <p className={`${styles.price} text-success`}>{data.price} tk</p>
                                                <small className={styles.prePrice}>{data.pre_price} tk</small>
                                            </div>
                                            <div className={styles.starSize}>
                                                {
                                                    data.ratings.length > 0 ?
                                                        <> <Star starRating={(data.ratings.reduce((total, value) => total + value.rating_point, 0) / data.ratings.length).toFixed(1)} /> <span>{`(${data.ratings.length})`}</span></> :
                                                        <Star starRating={5} />
                                                }
                                            </div>

                                            <div>
                                                {/* <button className={styles.orderBtn}>Order Now</button> */}
                                                <OrderModalView product={data} />
                                            </div>
                                            <AddToCart product={data} />
                                            {data.pre_price === null || data.pre_price === data.price || data.pre_price < data.price ?
                                                null :
                                                <p className={styles.offText}>{(100 - ((Number(data.price) / Number(data.pre_price)) * 100)).toFixed()}% off </p>}
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    );
}





// export function Star(props) {
//     const { starRating } = props
//     let count = 1;
//     const stars = [];
//     while (count <= starRating) {
//         stars.push(count)
//         count += 1
//     }
//     return (
//         stars.map((data) =>
//             <span className='startView' key={data}><FontAwesomeIcon className='text-warning star' icon={faStar} /> </span>

//         )
//     )
// }

export function Star(props) {
    const { starRating } = props;
    const fullStars = Math.floor(starRating);
    const hasHalfStar = starRating - fullStars >= 0.5;

    const renderStar = (index) => {
        if (index < fullStars) {
            return (
                <span className='starView' key={index}>
                    <FontAwesomeIcon className='text-warning star' icon={faStar} />
                </span>
            );
        } if (index === fullStars && hasHalfStar) {
            return (
                <span className='starView' key={index}>
                    <FontAwesomeIcon className='text-warning star' icon={faStarHalfAlt} />
                </span>
            );
        }
        return (
            <span className='starView' key={index}>
                <FontAwesomeIcon className={`star ${styles.blankStar}`} icon={faStar} />
            </span>
        );

    };

    const stars = Array(5).fill().map((_, index) => renderStar(index));

    return stars;
}


export default ProductView;