import React, {useContext} from 'react';
import { Helmet } from 'react-helmet';
import { CartContext } from '../CartContext';
import Search from '../search/Search/Search';

function ShopPage() {
    const {products} = useContext(CartContext)
    return (
        <div className='mt-5 pt-3'>
             <Helmet>
                <title> All Product !!  Royel Shopping !!</title>
            </Helmet>
            <div className='mt-5'>
                <h6 className='text-center'>All Products</h6>
                <Search allProducts={products} />
            </div>
        </div>
    );
}

export default ShopPage;