import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { CartContext } from '../CartContext';
import styles from './ProductView.module.css'



function AddToCart(props) {
    const { product } = props
    const { addToCart } = useContext(CartContext)
    const handleaddToCart = () => {
        addToCart(product)
        window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
                currency: "BDT",
                value: product.price,

                items: [
                    {
                        item_id: product.slug,
                        item_name: product.name,
                        quantity: 1,
                        price: product.price,
                    }
                ]
            }
        });
    }
    return (
        <div>
            <div className={styles.veiwCount}>
                {/* <div>
                    <button onClick={() => handleQuantuty("minus")} className={`${styles.count} ${styles.minusCount}`}>-</button> <span className={`${styles.count} ${styles.quantityCount}`}>{quantity}</span> <button className={`${styles.count} ${styles.plusCount}`} onClick={() => handleQuantuty("plus")}>+</button>
                </div> */}
                <button onClick={() => handleaddToCart(product)} className={`${styles.cartBtn}`} type='button'>
                    <FontAwesomeIcon icon={faCartPlus} />

                </button>
                {/* <QucikView product={product} /> */}
            </div>
        </div>
    );
}

export default AddToCart;